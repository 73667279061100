/*
 * @Author: 艾斯
 * @Description: 会员相关api
 * @Date: 2022-07-19 15:39:31
 * @LastEditTime: 2022-07-23 11:39:16
 * @FilePath: /sksaas-web/src/http/api/member.js
 */
import service from '../index'
import { mc, baseURL } from '../config';
import qs from 'qs';

// 获取会员状态枚举
export const getMemberStateEnum = () => {
  return service(baseURL)({
    url: '/index.php',
    method: 'get',
    params: { ...mc, a: 'get_student_status' }
  })
}

// 获取顾问
export const getAdviser = (params) => {
  return service(baseURL)({
    url: '/index.php',
    method: 'get',
    params: { ...mc, a: 'get_adviser',...params }
  })
}

// 获取消息模板列表
export const getMsgTemplate= () => {
  return service(baseURL)({
    url: '/index.php',
    method: 'get',
    params: { ...mc, a: 'get_msg_template' }
  })
}

// 获取消息模板详情
export const getMsgTemplateInfo= (params) => {
  return service(baseURL)({
    url: '/index.php',
    method: 'get',
    params: { ...mc, a: 'get_msg_templateinfo',...params }
  })
}


// 检索老师
export const getTeacher= (params) => {
  return service(baseURL)({
    url: '/index.php',
    method: 'get',
    params: { ...mc, a: 'get_teacher',...params }
  })
}

// 添加任务
export const addTask= (params) => {
  return service(baseURL)({
    url: '/index.php',
    method: 'post',
    params: { ...mc, a: 'addsendmsg'},
    data:qs.stringify(params)
  })
}

// 开始任务
export const startTask= (params) => {
  return service(baseURL)({
    url: '/index.php',
    method: 'post',
    params: { ...mc, a: 'startsendmsg'},
    data:qs.stringify(params)
  })
}

// 暂停任务
export const suspendTask= (params) => {
  return service(baseURL)({
    url: '/index.php',
    method: 'post',
    params: { ...mc, a: 'closesendmsg'},
    data:qs.stringify(params)
  })
}

// 删除任务
export const delTask= (params) => {
  return service(baseURL)({
    url: '/index.php',
    method: 'post',
    params: { ...mc, a: 'delsendmsg'},
    data:qs.stringify(params)
  })
}

// 获取任务列表
export const getTaskList= (params) => {
  return service(baseURL)({
    url: '/index.php',
    method: 'get',
    params: { ...mc, a: 'getsendmsglist',...params},
  })
}
