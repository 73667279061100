import axios from 'axios'

const showStatus = status => {
  let message = ''
  switch (status) {
    case 400:
      message = '请求错误(400)'
      break
    case 401:
      message = '未授权，请重新登录(401)'
      break
    case 403:
      message = '拒绝访问(403)'
      break
    case 404:
      message = '请求出错(404)'
      break
    case 408:
      message = '请求超时(408)'
      break
    case 500:
      message = '服务器错误(500)'
      break
    case 501:
      message = '服务未实现(501)'
      break
    case 502:
      message = '网络错误(502)'
      break
    case 503:
      message = '服务不可用(503)'
      break
    case 504:
      message = '网络超时(504)'
      break
    case 505:
      message = 'HTTP版本不受支持(505)'
      break
    default:
      message = `连接出错(${status})!`
  }
  return `${message}，请检查网络或联系管理员！`
}

const service = (url = 'https://sapi.sikegroup.com') => {
  const http = axios.create({
    // baseURL: process.env.NODE_ENV === 'production' ? `http://sapi.sikegroup.com` : 'http://sapi.sikegroup.com',
    baseURL: url,
    headers: {
      get: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      post: {
        // 'Content-Type': 'application/json;charset=utf-8'
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    },
    // 是否允许携带cookie
    withCredentials: false,
    timeout: 30000,
    // 向服务器发送前，修改请求数据
    transformResponse: [
      data => {
        if (typeof data === 'string' && data.startsWith('{')) {
          data = JSON.parse(data)
        }
        return data
      }
    ]
  })
  // 请求拦截器
  http.interceptors.request.use(
    config => {
      const authentication = window.sessionStorage.getItem('userInfo')
        ? 'USERID ' +
          window.btoa(
            JSON.parse(window.sessionStorage.getItem('userInfo')).appid +
              ':' +
              window.sessionStorage.getItem('access_token') +
              ':' +
              JSON.parse(window.sessionStorage.getItem('userInfo')).uid
          )
        : ''
      if (authentication) {
        config.headers.authentication = authentication
      }
      return config
    },
    error => {
      // 错误抛到业务代码
      error.data = {}
      error.data.msg = '服务器异常，请联系管理员！'
      return Promise.resolve(error)
    }
  )

  // 响应拦截器
  http.interceptors.response.use(
    response => {
      const status = response.status
      let msg = ''
      if (status < 200 || status >= 300) {
        // 处理http错误，抛到业务代码
        msg = showStatus(status)
        if (typeof response.data === 'string') {
          response.data = { msg }
        } else {
          response.data.msg = msg
        }
      }
      return response.data
    },
    error => {
      // 错误抛到业务代码
      error.data = {}
      error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'
      return Promise.resolve(error)
    }
  )
  return http
}

export default service
