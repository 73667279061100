<template>
	<div class="hdTicket">
		<div class="page-header flex">
			<span style="font-size: 16px;">发送详情管理</span>
			<div>
			 
			</div>
		</div>
		<div class="page-main">
			<div class="screen-list">
				<a-form-model layout="inline" :model="souForm">
					<a-form-model-item label="状态">
						<a-select placeholder="请选择" v-model="souForm.status" allowClear>
							<a-select-option value="">全部</a-select-option>
							<a-select-option value="1">已发送</a-select-option>
							<a-select-option value="0">待发送</a-select-option>
                            <a-select-option value="2">发送失败</a-select-option>
						</a-select>
					</a-form-model-item>
				 
				</a-form-model>
				<div class="form-btn">
					<a-button @click="_resetBtn">重置条件</a-button>
					<a-button type="primary" @click="_searchBtn">开始查询</a-button>
				</div>
			</div>
			<div class="todo-content">
				<a-table     :columns="columns" :dataSource="infoData" :pagination="false" bordered v-if="infoData">
                    <div slot="StatusText" slot-scope="text">
					 
							<span class="status-icon"
								:style="text=='发送失败'?'background: #E81B1C':text=='待发送'?'background:orangered':'background: #4199F3'"></span> {{text}}
						 
					</div>
					<div slot="Operation" slot-scope="text, row">
			 		 
						 
						<a-popconfirm title="您确定要删除吗?" @confirm="_delTableRow(row)" okText="确定" cancelText="取消">
							<a-icon type="delete" class="icon-btn" v-if="row.DelBoolean==0" />
						</a-popconfirm>
						 
					</div>
				</a-table>

				<div style="margin: 20px 0; text-align: right;">
					<a-pagination showQuickJumper :defaultCurrent="1" :total="pageTotal" @change="_PageChange" />
				</div>
			</div>


		</div>
	</div>
</template>

<script>
  
	const columns = [
   
	    {
			title: '姓名',
			dataIndex: 'stu_name',
			align: 'center'
		},
		{
			title: '发送时间',
			dataIndex: 'sendtime',
			align: 'center'
		},
		{
			title: '状态',
			dataIndex: 'StatusText',
			align: 'center',
            scopedSlots: {
				customRender: 'StatusText'
			}
		},
		{
			title: '操作',
			dataIndex: 'Operation',
			align: 'center',
			scopedSlots: {
				customRender: 'Operation'
			}
		}
	]
	export default {
		name: 'messageDetail', 
		data() {
			return {
				columns: columns,
				pageTotal: 0,
				page: 1,
				souForm: {
					status: undefined, //关键字
					MId:undefined,
				},
				infoData: [],
				isEdit: false,
				formData: {} 
                // bookSelectedRowKeys:[],
                // bookSelectedRow:[]
			}
		},
      props: {
        FieldModelId: {
            type: String,
        } 
         },
		created() {
            this.souForm.MId=this.FieldModelId
         
			this._info()
         
		},
		methods: {
		
			// 检索
			_searchBtn() {
				this.page = 1
				this._info()
			},
			// 重置
			_resetBtn() {
				this.souForm.status = undefined
			 
			}, 
            	// 删除
			_delTableRow(index) {
				console.log(index)
					console.log(index._id.$oid)
				this.$axios.post(
					80850002, {
						id: index._id.$oid
					},
					res => {
						if (res.data.code == 1) {
							this.$message.success(res.data.msg)
						} else {
							this.$message.success(res.data.msg)
						}
						this._info()
					}
				)
			},
		
			// 分页事件
			_PageChange(page) {
				this.page = page
				this._info()
			},
		 
			// 获取表格内容
			_info() {
				this.$message.loading('加载中...')
				// let department = this.souForm.department.join()
				let data = {
					Status:this.souForm.status,
					MId:this.FieldModelId,
					Page: this.page,
					PageSize: 10
				}
				this.$axios.get(80850001, data, res => {
					if (res.data.code == 1) {
						this.infoData = res.data.data
						this.pageTotal = res.data.count
						this.page = this.page
					} else {
						this.pageTotal = 1
						this.infoData = []
					}
					this.$message.destroy()
				})
			}  
			 
			 
		}
	}
</script>

<style scoped lang="less">
	.hdTicket {
		width: 100%;
		background: #fff;
		box-sizing: border-box;
		font-size: 14px;

		.page-header {
			height: 55px;
			line-height: 55px;
			padding: 10px 20px;
			border-bottom: 1px solid #e1e2e3;

			.screen-btn {
				width: 100px;
				display: inline-block;
				font-size: 12px;
				margin-left: 20px;
				border-width: 1px;
				border-style: solid;
				border-radius: 20px;
				padding: 9px 0;
				line-height: 1;
				text-align: center;
				background: #fff !important;
				cursor: pointer;

				i {
					margin-right: 4px;
				}
			}
		}

		.page-main {
			padding: 20px;

			.screen-list {
				margin: 0 0 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				:deep(.ant-select-selection--single),
      :deep(.ant-form-item-control) {
        min-width: 150px;
      }

				.form-btn {
					button+button {
						margin-left: 10px;
					}
				}
			}
		}

		.icon-btn {
			cursor: pointer;
			margin-left: 10px;
			font-size: 18px;
		}
        .status-icon {
			display: inline-block;
			vertical-align: middle;
			width: 8px;
			height: 8px;
			border-radius: 50%;
		}
        .drawer-bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-top: 1px solid #e9e9e9;
            padding: 10px 16px;
            background: #fff;
            }
	}
</style>
