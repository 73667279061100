/*
 * @Author: 艾斯
 * @Description: 服务器2配置
 * @Date: 2022-07-20 09:04:27
 * @LastEditTime: 2022-07-20 09:05:00
 * @FilePath: /sksaas-web/src/http/config.js
 */
const mc = {
  m: 'Kms',
  c: 'Testmsg'
}
const baseURL = 'https://ims.qitianzhen.cn'

export {mc,baseURL}