/*
 * @Author: 艾斯
 * @Description: 部门相关api
 * @Date: 2022-07-19 11:09:18
 * @LastEditTime: 2022-07-20 11:13:50
 * @FilePath: /sksaas-web/src/http/api/department.js
 */
import service from '../index';
import { mc, baseURL} from '../config';

// 获取部门下拉选项
export const getDepartmentList = (params) => {
  return service()({
    url:'/user/v1/Organization',
    method: 'get',
    params:params
  })
}

// 获取部门名称
export const getDepartmentName = (params) => {
  return service(baseURL)({
    url: '/index.php',
    method: 'get',
    params: { ...mc, a: 'get_orgname',...params }
  })
}