<!--
 * @Author: 艾斯
 * @Description: 消息管理
 * @Date: 2022-07-16 16:43:16
 * @LastEditTime: 2022-08-03 09:56:14
 * @FilePath: /sksaas-web/src/pages/message/Index.vue
-->
<template>
  <div class="message">
    <div class="title">
      <el-button type="primary" size="small" @click="formDialogVisible = true">创建</el-button>
      <h2>消息管理</h2>
    </div>
    <!-- 表格 -->
    <div class="tabel" v-if="isShowTabel">
      <m-tabel
        :tableHead="tableHead"
        :tableData="tableData"
        :isShowPage="true"
        :pageSize="pageSize"
        :total="total"
        :loading="tableLoding"
        :currentPage="currentPage"
        maxHeight="700px"
        @pageChange="pageChange"
      >
   
        <template #slot="scope">
          <template v-if="scope.label === '状态'">
            {{ scope.row.status | statusF }}
          </template>
          <template v-if="scope.label === '发送进度'">
            {{ scope.row.progress }} <a-icon type="bars"  @click="_dataListAction(true,scope.row.id)" class="icon-btn"></a-icon>
          </template>
          <template v-if="scope.label === '创建人'">
            {{ scope.row.createuser }} <br />
            {{ scope.row.createtime }}
          </template>
          <template v-if="scope.label === '操作'">
            <el-button type="text" @click="delTask(scope.row.id)">
              删除
            </el-button>
            <el-button type="text" @click="startTask(scope.row.id)" v-if="scope.row.status != 1">
              开始
            </el-button>
            <el-button type="text" @click="suspendTask(scope.row.id)" v-if="scope.row.status == 1">
              暂停
            </el-button>
          </template>
        </template>
      </m-tabel>
    </div>
    
		<a-drawer v-if="dataListSatus" title="信息详情列表" width="1200" :closable="false" :visible="dataListSatus" @close="_dataListAction(false)">
            <messageDetail   :FieldModelId="FieldModelId" @visible="_dataListAction(false)"></messageDetail>
        </a-drawer>
    <!-- 添加和编辑 -->
    <m-dialog type="drawer" :dialogVisible.sync="formDialogVisible" :saveLoding.sync="saveLoding" :showLoading="true" @close="dialogClose" @saveSubmit="saveSubmit">
      <m-form ref="myForm" :formItemOptions="formItemOptions" :rules="rules" width="700px">
        <template #slot="scope">
          <template v-if="scope.row.model === 'senduser'">
            <div class="tag-box" @click="selectRecipient" v-if="placeholder.length > 0">
              <div class="tag-group" v-for="item in placeholder" :key="item.label">
                <span class="tag-group__title">{{ item.label }}</span>
                <div>
                  <el-tag v-for="(row, index) in item.value" :key="index" type="info"> {{ row.label }}:{{ row.value }} </el-tag>
                </div>
              </div>
            </div>
            <el-input v-else :placeholder="scope.row.placeholder" clearable @focus="selectRecipient"></el-input>
          </template>
          <template v-if="scope.row.model === 'tid'">
            <el-select v-model="form.tid" filterable @change="msgTemplateChange">
              <el-option v-for="item in msgTemplate" :key="item.Id" :label="item.Title" :value="item.Id"> </el-option>
            </el-select>
          </template>
          <template v-if="scope.row.model === 'sendtype'">
            <el-radio-group v-model="form.sendtype">
              <el-radio :label="1">立即发送</el-radio>
              <el-radio :label="2">预约发送</el-radio>
            </el-radio-group>
          </template>
          <template v-if="scope.row.model === 'sendtime'">
            <template v-if="form.sendtype !== 2">
              立即
            </template>
            <el-date-picker v-model="form.sendtime" type="datetime" value-format="yyyy-MM-dd" placeholder="选择日期" v-else />
          </template>
        </template>
      </m-form>
    </m-dialog>
    <!-- 选择接收人弹窗 -->
    <m-dialog :dialogVisible.sync="recipientDialogVisible" title="选择接收人" @close="dialogClose" @saveSubmit="selectSubmit">
      <el-form label-width="120px">
        <el-tabs v-model="activeName">
          <el-tab-pane label="部门" name="department">
            <el-form-item label="所属部门">
              <el-cascader v-model="recipientDepartment.ids" :options="recipientOption" :props="{ label: 'title' }" clearable filterable collapse-tags @change="recipientChange" />
            </el-form-item>
            <el-form-item label="会员状态">
              <el-select v-model="recipientDepartment.status" clearable filterable>
                <el-option v-for="item in memberStateEnum" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="顾问">
              <el-select v-model="recipientDepartment.tname" clearable filterable>
                <el-option v-for="item in adviser" :key="item.Id" :label="item.Name" :value="item.Id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出生日期范围">
              <el-date-picker v-model="recipientDepartment.dtime" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" />
            </el-form-item>
            <el-form-item label="剩余课次">
              <el-input-number v-model="recipientDepartment.num"></el-input-number>
            </el-form-item>
            <el-form-item label="首次报名日期">
              <el-date-picker v-model="recipientDepartment.rtime" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" />
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="课表" name="timetable">
            <el-form-item label="上课日期范围">
              <el-date-picker v-model="recipientTimetable.time" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" />
            </el-form-item>
            <el-form-item label="课程类型">
              <el-select v-model="recipientTimetable.classtype" clearable filterable>
                <el-option v-for="item in courseType" :key="item.Id" :label="item.Name" :value="item.Id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="老师">
              <el-autocomplete v-model="recipientTimetable.teacher" :fetch-suggestions="querySearch" placeholder="请输入老说名字" :trigger-on-focus="false"></el-autocomplete>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="指定电话" name="phone">
            <el-form-item label="电话">
              <el-input placeholder="请输入电话号码" v-model="recipientPhone" clearable> </el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="指定ID" name="id">
            <el-form-item label="ID"> <el-input placeholder="请输入接收人id" v-model="recipientId" clearable> </el-input></el-form-item>
          </el-tab-pane>
          <el-tab-pane label="体验课表" name="experienceSchedule">
            <el-form-item label="所属部门">
              <el-cascader v-model="recipientExperience.ids" :options="recipientOption" :props="{ label: 'title' }" clearable filterable collapse-tags @change="recipientChange2" />
            </el-form-item>
            <el-form-item label="体验日期范围">
              <el-date-picker v-model="recipientExperience.time" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" />
            </el-form-item>
            <el-form-item label="是否签到">
              <el-radio-group v-model="recipientExperience.status">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </m-dialog>
  </div>
</template>

<script>
import { getDepartmentList, getDepartmentName } from '@/http/api/department.js'
import { getMemberStateEnum, getAdviser, getMsgTemplate, getMsgTemplateInfo, getTeacher, addTask, startTask, delTask, suspendTask, getTaskList } from '@/http/api/member.js'
import { getCourseType } from '@/http/api/course.js'
import messageDetail from 'pages/message/messageDetail'
import { Message } from 'element-ui'
import { MessageBox } from 'element-ui';
export default {
  components: {
	 messageDetail
		},
  data() {
    return {
      formDialogVisible: false,
      saveLoding: false,
      form: {
        title: null,
        tid: null,
        sendtype: 1,
        sendtime: null,
        senduser: null
      },
      oldFormItemOptions: [],
      formItemOptions: [
        {
          model: 'title',
          label: '任务名称',
          value: '',
          placeholder: '请输入'
        },
        {
          type: 'slot',
          model: 'senduser',
          label: '接收人',
          placeholder: '请选择接收人'
        },
        {
          type: 'slot',
          model: 'tid',
          label: '模板',
          placeholder: '请选消息模板'
        },
        {
          type: 'slot',
          model: 'sendtype',
          label: '选项'
        },
        {
          type: 'slot',
          model: 'sendtime',
          label: '发送时间'
        }
      ],
      rules: {},
      placeholder: [],
      recipientDialogVisible: false,
      activeName: 'department',
      // 部门类型检索条件
      recipientDepartment: {
        org: null,
        ids: null,
        status: null,
        tname: null,
        dtime: null,
        rtime: null,
        num: null
      },
      // 课表类型检索
      recipientTimetable: {
        time: null,
        classtype: null,
        teacher: null
      },
      // 指定电话检索
      recipientPhone: null,
      // 指定id检索
      recipientId: null,
      recipientExperience: {
        org: null,
        ids: null,
        time: null,
        status: null
      },

      recipientOption: [],
      memberStateEnum: [],
      adviser: [],
      courseType: [],
      msgTemplate: [],
      msgTemplateNum: 0,
      isShowTabel:false,
      // 表格配置
      tableHead: [
        { prop: 'title', label: '任务名称', width: 200 },
        { prop: 'progress', label: '发送进度', width: 200, isSlot: true  },
        { prop: 'content', label: '消息内容' },
        { prop: 'createuser', label: '创建人', width: 200, isSlot: true },
        { prop: 'status', label: '状态', width: 100, isSlot: true },
        { prop: '', label: '操作', width: 140, isSlot: true }
      ],
      tableData: [],
      tableLoding: false,
      dataListSatus:false,
      currentPage: 1,
      FieldModelId:"",
      pageSize: 10,
      total: 0
    }
  },
  filters: {
    statusF: function (val) {
      val = +val
      let str
      switch (val) {
        case 1:
          str = '已开始'
          break
        case 2:
          str = '暂停中'
          break
        default:
          str = '未开始'
          break
      }
      return str
    }
  },
  created() {
    this.getDepartmentList()
    this.getMemberStateEnum()
    this.getCourseType()
    this.getMsgTemplate()
    this.getTaskList()
  },
  methods: {
      _dataListAction(val,id){
                this.dataListSatus = val;
                if(id){
                    this.FieldModelId = id ;              
                }
		
    },
    // 分页事件
    pageChange(page) {
      this.currentPage = page
      this.getTaskList()
    },
    // 开始任务
    startTask(id) {
      // startTask({ id }).then(res => {
      //   if (res.code === 1) {
      //     Message({
      //       message: res.msg,
      //       type: 'success'
      //     })
      //     this.getTaskList()
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // })

      this.$axios.post(9999999,{ id },res => {
        if(res.data.code===1){
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg)
        }
      });
    },
    // 暂停任务
    suspendTask(id) {
      suspendTask({ id }).then(res => {
        if (res.code === 1) {
          Message({
            message: res.msg,
            type: 'success'
          })
          this.getTaskList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 删除任务
    delTask(id) {
      MessageBox.confirm('此操作将永久删除该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTask({ id }).then(res => {
            if (res.code === 1) {
              Message({
                message: res.msg,
                type: 'success'
              })
              this.getTaskList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    // 获取任务列表
    getTaskList() {
      this.tableLoding = true
      getTaskList({ p: this.currentPage, limit: this.pageSize }).then(res => {
        this.tableData = [...res.data]
        this.total = +res.total
        this.tableLoding = false
        this.isShowTabel = true
      })
    },
    // 获取部门下拉选项
    getDepartmentList() {
      getDepartmentList().then(res => {
        this.recipientOption = [...res.data]
      })
    },
    // 获取部门名称
    getDepartmentName(id) {
      return getDepartmentName({ oid: id })
    },
    // 获取会员状态枚举
    getMemberStateEnum() {
      getMemberStateEnum().then(res => {
        this.memberStateEnum = [...res.data]
      })
    },
    // 获取顾问
    getAdviser(oid) {
      if (!oid) {
        return false
      }
      getAdviser({ oid }).then(res => {
        if (res.code === 1) {
          this.adviser = [...res.data]
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 选择部门事件
    recipientChange(value) {
      const val = value.length > 0 ? value[value.length - 1] : ''
      this.recipientDepartment.org = val
      this.getAdviser(val)
    },
    recipientChange2(value) {
      const val = value.length > 0 ? value[value.length - 1] : ''
      this.recipientExperience.org = val
    },
    // 获取课程类型
    getCourseType() {
      getCourseType().then(res => {
        this.courseType = [...res.data]
      })
    },
    // 获取消息模板
    getMsgTemplate() {
      getMsgTemplate().then(res => {
        this.msgTemplate = [...res.data]
      })
    },
    // 获取模板详情
    getMsgTemplateInfo(id) {
      if (!id) {
        return false
      }
      getMsgTemplateInfo({ id }).then(res => {
        if (res.data.length > 0) {
          let arr = this.oldFormItemOptions.filter(item => {
            return this.formItemOptions.some(res => res.model === item.model)
          })
          if (arr.length > 0) {
            this.formItemOptions = [...arr]
          }
          this.oldFormItemOptions = [...this.formItemOptions]
          for (const item of res.data) {
            this.formItemOptions.push({ model: item[1], label: item[0] })
          }
        } else {
          this.$message.error('获取数据失败')
        }
      })
    },
    // 选择模板消息事件
    msgTemplateChange(val) {
      this.getMsgTemplateInfo(val)
    },
    // 查找老师
    querySearch(str, cb) {
      getTeacher({ tname: str }).then(res => {
        if (res.data.length > 10) {
          res.data.length = 10
        }
        let arr = []
        for (const item of res.data) {
          arr.push({ value: item.Name })
        }
        cb(arr)
      })
    },
    dialogClose() {},
    // 提交事件
    saveSubmit() {
      let senduser = {
        Sid: this.recipientId,
        Stel: this.recipientPhone,
        Sclass: { ...this.recipientTimetable },
        Sorg: { ...this.recipientDepartment },
        Styclass: { ...this.recipientExperience }
      }

      // 提交表单事件
      this.$refs.myForm
        .submitForm()
        .then(res => {
          let data = {
            title: res.formInfo.title,
            tid: this.form.tid,
            sendtype: this.form.sendtype,
            sendtime: this.form.sendtime
          }
          data.senduser = { ...senduser }
          // 去除重复数据
          for (const key in this.form) {
            delete res.formInfo[key]
          }
          data.content = {}
          for (const key in res.formInfo) {
            data.content[key] = res.formInfo[key]
          }
          addTask(data).then(row => {
            if (row.code === 1) {
              Message({
                message: '添加成功！',
                type: 'success'
              })
              // 清楚表单数据
              this.emptyForm()
              this.currentPage = 1
              this.getTaskList()
              this.formDialogVisible = false
            } else {
              this.$message.error(row.msg)
            }
            this.saveLoding = false
          })
        })
        .catch(() => {
          this.saveLoding = false
        })
    },
    // 选择接收人弹窗
    selectRecipient() {
      this.recipientDialogVisible = true
    },
    async selectSubmit() {
      // 部门检索类型
      let departmentValue = []
      for (const key in this.recipientDepartment) {
        let label,
          obj,
          value = this.recipientDepartment[key]
        if (key === 'ids' || !value) {
          continue
        }
        switch (key) {
          case 'org':
            obj = await this.getDepartmentName(value)
            label = '部门'
            value = obj.data.OrgName
            break
          case 'status':
            label = '会员状态'
            value = this.memberStateEnum.find(item => item.value == value).label
            break
          case 'tname':
            label = '顾问'
            value = this.adviser.find(item => item.Id == value).Name
            break
          case 'dtime':
            label = '出生日期范围'
            value = value.join(' 至 ')
            break
          case 'num':
            label = '剩余课次'
            break
          case 'rtime':
            label = '首次报名日期范围'
            value = value.join(' 至 ')
            break
        }
        departmentValue.push({ label, value })
      }
      if (departmentValue.length > 0) {
        this.placeholder = [{ label: '部门', value: [...departmentValue] }]
      }
      // 课表检查类型
      let timetableValue = []
      for (const key in this.recipientTimetable) {
        let label,
          value = this.recipientTimetable[key]
        if (!value) {
          continue
        }
        switch (key) {
          case 'time':
            label = '上课日期范围'
            value = value.join(' 至 ')
            break
          case 'classtype':
            label = '课程类型'
            value = this.courseType.find(item => item.Id == value).label
            break
          case 'teacher':
            label = '老师'
            break
        }
        timetableValue.push({ label, value })
      }
      if (timetableValue.length > 0) {
        this.placeholder = [...this.placeholder, { label: '课表', value: [...timetableValue] }]
      }
      // 电话检索
      if (this.recipientPhone) {
        this.placeholder = [
          ...this.placeholder,
          {
            label: '指定电话',
            value: [
              {
                label: '电话',
                value: this.recipientPhone
              }
            ]
          }
        ]
      }
      // id检索
      if (this.recipientId) {
        this.placeholder = [
          ...this.placeholder,
          {
            label: '指定Id',
            value: [
              {
                label: 'ID',
                value: this.recipientId
              }
            ]
          }
        ]
      }
      // 体验课表
      let recipientExperience = []
      for (const key in this.recipientExperience) {
        let label,
          obj,
          value = this.recipientExperience[key]
        if (key === 'ids' || !value) {
          continue
        }
        switch (key) {
          case 'org':
            obj = await this.getDepartmentName(value)
            label = '部门'
            value = obj.data.OrgName
            break
          case 'time':
            label = '体验日期范围'
            value = value.join(' 至 ')
            break
          case 'status':
            label = '是否签到'
            value = value === 1 ? '是' : '否'
            break
        }
        recipientExperience.push({ label, value })
      }
      if (recipientExperience.length > 0) {
        this.placeholder = [...this.placeholder, { label: '体验课表', value: [...recipientExperience] }]
      }
      this.recipientDialogVisible = false
    },
    // 清楚表单数据
    emptyForm() {
      for (const key in this.recipientDepartment) {
        this.$set(this.recipientDepartment,key,null)
      }
      for (const key in this.recipientTimetable) {
        this.$set(this.recipientTimetable,key,null)
      }
      for (const key in this.recipientExperience) {
        this.$set(this.recipientExperience,key,null)
      }
      this.recipientPhone = null
      this.recipientId = null
      for (const key in this.form) {
        if (key === 'sendtype') {
          this.form[key] = 1
        } else {
          this.form[key] = null
        }
      }
      this.placeholder = []
    }
  }
}
</script>
<style lang="less" scoped>
.message {
  padding: 20px;
  background-color: #fff;
  .title {
    padding: 15px;
    .el-button {
      float: right;
    }
  }
  .tag-box {
    border: 1px solid #ebeef5;
    padding: 5px 15px;
    .tag-group {
      display: flex;
      .tag-group__title {
        width: 60px;
      }
      .el-tag {
        & + .el-tag {
          margin-left: 5px;
        }
      }
    }
  }
  .tabel {
    padding: 0 15px 15px;
  }
  	.icon-btn {
			cursor: pointer;
			margin-left: 10px;
			font-size: 18px;
		}
}
</style>
