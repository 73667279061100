/*
 * @Author: 艾斯
 * @Description: 课程相关api
 * @Date: 2022-07-20 11:13:41
 * @LastEditTime: 2022-07-20 11:15:12
 * @FilePath: /sksaas-web/src/http/api/course.js
 */

import service from '../index';

// 获取课程类型
export const getCourseType= (params) => {
  return service()({
    url:'/api/v1.report/class_category',
    method: 'get',
    params:params
  })
}